
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  margin-top: 30px;
  cursor: pointer;
}

.profile-photo {
  width: 300px;
  height: 300px;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  border: 8px double darkgray;
}

.custom-file-upload:hover{
  background-color: #adacae;
}




